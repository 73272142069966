import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbAuthToken } from '@nebular/auth';
import { Observable } from 'rxjs';
import { LoginService } from './service/login.service';

/**
 * Intercept every request and add a token to header.
 *
 * @export
 * @class BearerInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class BearerInterceptor implements HttpInterceptor {

  token: NbAuthToken;

  loginService: LoginService;

  constructor(
    loginService: LoginService
  ) {
    this.loginService = loginService;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let authReq = req.clone({
      withCredentials: true
    });

    this.token = this.loginService.getToken();

    console.log(`Token válido ${this.token?.isValid()} e Url: ${req.url}`);

    if (this.token && this.token.getValue() !== '') {
      authReq = this.addToken(req, this.token.getValue());
    }

    return next.handle(authReq);
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
}
