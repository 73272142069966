import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import {
  NbActionsModule, NbButtonModule, NbContextMenuModule, NbIconModule, NbLayoutModule,
  NbMenuModule,
  NbSearchModule, NbSelectModule, NbSidebarModule, NbThemeModule, NbUserModule
} from "@nebular/theme";
import {
  FooterComponent,
  HeaderComponent
} from "./components";
import { GuestHeaderComponent } from "./components/guest/header/guest-header.component";
import { PublicFooterComponent } from './components/public-footer/public-footer.component';
import { RecruitmentHeaderComponent } from './components/recruitment/recruitment-header/recruitment-header.component';
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent
} from "./layouts";
import { GuestMainLayoutComponent } from "./layouts/guest/guest.layout";
import { RecruitmentLayoutComponent } from './layouts/recruitment-layout/recruitment.layout';
import {
  CapitalizePipe, NumberWithCommasPipe, PluralPipe,
  RoundPipe,
  TimingPipe
} from "./pipes";
import { CORPORATE_THEME } from "./styles/theme.corporate";
import { COSMIC_THEME } from "./styles/theme.cosmic";
import { DARK_THEME } from "./styles/theme.dark";
import { DEFAULT_THEME } from "./styles/theme.default";
import { NbSecurityModule } from "@nebular/security";


const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbSecurityModule
];
const COMPONENTS = [
  // Authorized
  HeaderComponent,
  FooterComponent,
  // Guest
  GuestHeaderComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  GuestMainLayoutComponent,
  // Recruitment
  PublicFooterComponent,
  RecruitmentLayoutComponent,
  RecruitmentHeaderComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
];

@NgModule({
  imports: [CommonModule, ...NB_MODULES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {
  static forRoot(themeName = "encante"): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: themeName,
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME]
        ).providers,
      ],
    };
  }
}
