/**
 * Lista de permissões do app, cada usuário pode herdar funções e cada função deve ser descrita aqui.
 */
export const accessControl = {
    accessControl: {
        guest: {
            view: ['comments'],
        },
        user: {
            parent: 'guest',
            view: [
                'menu',
                'product-add',
                'product-edit',
                'product-list',
                'client-list'
            ],
        },
        seller: {
            view: [
                'products',
                'menu', 
                'product-list',
                'client-list'
            ],
            create: ['sell'],
            remove: ['sell'],
        },
        admin: {
            view: '*',
            create: '*',
            edit: '*',
            remove: '*',
        }
    }
};