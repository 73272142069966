import { environment } from '../../../../../environments/environment';
import { formatDate } from "@angular/common";
import { Component } from "@angular/core";

@Component({
  selector: "ngx-public-footer",
  styleUrls: ["./public-footer.component.scss"],
  template: `
    <span class="pull-right m-3">
      <b>Joias Encante<a href="#" target="_blank"></a></b> - {{ date }}
    </span>
    <div class="socials">
      <small class="caption"> Tel: (71) 98750-5444 </small><br>
      <small class="caption"> Email: contato@joiasencante.com.br </small>
      
      <!-- <a href="#" target="_blank" class="ion ion-social-github"></a> -->
      <!-- <a href="#" target="_blank" class="ion ion-social-facebook"></a> -->
      <!-- <a href="#" target="_blank" class="ion ion-social-twitter"></a> -->
      <!-- <a href="#" target="_blank" class="ion ion-social-linkedin"></a> -->
      <a
        href="https://www.instagram.com/joiasencanteacess/"
        target="_blank"
        class="ion ion-social-instagram"
      ></a>
    </div>
  `,
})
export class PublicFooterComponent {
  environment = environment;
  date = formatDate(new Date(), "yyyy", "en");
}
