import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy } from "@angular/core";
import { NbAccessChecker } from "@nebular/security";
import {
  NbMenuItem,
  NbMenuService,
  NbSidebarService
} from "@nebular/theme";
import { Subject } from "rxjs";
import { LayoutService } from "../../../../@core/utils";


@Component({
  selector: "ngx-recruitment-header",
  styleUrls: ["./recruitment-header.component.scss"],
  templateUrl: "./recruitment-header.component.html",
})
export class RecruitmentHeaderComponent implements OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  items: NbMenuItem[] = [
    {
      title: 'Inicio',
      link: '/recrutamento/registro',
      fragment: 'inicio'
    },
    {
      title: 'Proposta',
      link: '/recrutamento/registro',
      fragment: 'proposta'
    },
    {
      title: 'Beneficios',
      link: '/recrutamento/registro',
      fragment:'beneficios'
    },
    {
      title: 'Produtos',
      link: '/recrutamento/registro',
      fragment: 'produtos'
    },
    {
      title: 'Seja uma consultora',
      link: '/recrutamento/registro',
      fragment: 'consultora'
    },
    {
      title: 'Cadastro',
      link: '/recrutamento/registro',
      fragment: 'cadastro'
    },
  ];


  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private layoutService: LayoutService,
    public accessChecker: NbAccessChecker,
    private breakpointObserver: BreakpointObserver
  ) {

    // detect screen size changes
    this.breakpointObserver.observe([
      "(max-width: 768px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  }



  ngOnInit() { }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  mobile: boolean;

  // moveBetweenFragments() {
  //   this.router.navigate( ['routeexample1' ], {fragment: 'structure'});
  // }

}
