<div class="header-container">
  <div class="logo-container">
    <a *ngIf="mobile" (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline" style="color: #ffffff;"></nb-icon>
    </a>
    <img src="assets\images\logo-fundo-transparente-65x65.png">
  </div>
  <div class="header-container" class="w-100 d-flex justify-content-center">
    <!-- <div class="topnav">
      <a class="active" href="#home">Inicio</a>
      <a routerlink="." href="#revender">Vantagens</a>
      <a href="#contact">Contato</a>
      <a href="#about">Cadastro</a>
    </div> -->
    <nb-menu #horizontalMenu *ngIf="!mobile" class="horizontal-menu black-menu" [items]="items"></nb-menu>
  </div>
</div>