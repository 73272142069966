import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";
import { LoginService } from './../service/login.service';

@Injectable()
export class RedirectGuard implements CanActivate {

  constructor(private _loginService: LoginService, private _router: Router) { }

  authenticated = false;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    console.log("Redirect Guard next:", next);
    console.log("Redirect Guard state:", state);

    return true;
  }
}
