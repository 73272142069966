import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";
import { LoginService } from './../service/login.service';

/**
 * Check if token is active, otherwise redirect to login page
 *
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 */
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private _loginService: LoginService, private _router: Router) { }

  authenticated = false;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log('authenticated');

    if (this._loginService.getToken()) {
      this.authenticated = this._loginService.getToken().isValid();
    } else {
      this.authenticated = false;
    }
    console.log('authenticated2');
    if (this.authenticated) {
      return true;
    } else {
      // navigate to login page
      this._router.navigate(["/auth/login"], { queryParams: { url: state.url } });
      console.log('authenticated3');
      // you can save redirect url so after authing we can move them back to the page they requested
      return false;
    }
  }
}
