import { UserRoles } from './../model/user-roles';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { User } from "../model/user";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class UserService {

  constructor(private http: HttpClient) { }

  findAll(): Observable<User[]> {
    return this.http.get<User[]>(environment.backend.baseURL + `/users`);
  }

  findOne(id: number): Observable<User> {
    return this.http.get<User>(environment.backend.baseURL + `/users/` + id);
  }

  findByNickname(nickname: string): Observable<User> {
    return this.http.get<User>(
      environment.backend.baseURL + `/users/nickname`,
      { params: { nickname: nickname } }
    );
  }

  save(user: User): Observable<User> {
    return this.http.post<User>(environment.backend.baseURL + `/users`, user);
  }

  update(user: User): Observable<User> {
    return this.http.put<User>(environment.backend.baseURL + `/users`, JSON.stringify(user), {
      headers: this.getDefaultHeaders()
    });
  }

  delete(user: User): Observable<void> {
    return this.http.delete<void>(environment.backend.baseURL + `/users/` + user.id);
  }

  deleteRole(user: UserRoles): Observable<void> {
    return this.http.delete<void>(environment.backend.baseURL + `/users/roles/` + user.id);
  }

  findAllRoles(): Observable<UserRoles[]> {
    return this.http.get<UserRoles[]>(environment.backend.baseURL + `/users/roles`);
  }

  findOneRole(id: number): Observable<UserRoles> {
    return this.http.get<UserRoles>(environment.backend.baseURL + `/users/roles/` + id);
  }

  updateRole(user: UserRoles): Observable<UserRoles> {
    return this.http.put<UserRoles>(environment.backend.baseURL + `/users/roles`, user);
  }

  saveRole(user: UserRoles): Observable<UserRoles> {
    return this.http.post<UserRoles>(environment.backend.baseURL + `/users/roles`, user);
  }

  updatePassword(id: number, newPassword: string, oldPassword: string): Observable<User> {
    console.log(`id=${id}, newPassword=${newPassword}, oldPassword=${oldPassword}`);
    return this.http.put<User>(environment.backend.baseURL + `/users/password`, JSON.stringify(
      {
        id: id,
        newPassword: newPassword,
        actualPassword: oldPassword
      }
    ), {
      headers: this.getDefaultHeaders()
    });
  }

  /**
 * return default headers.
 * 
 * @return {*} 
 * @memberof ProductService
 */
  getDefaultHeaders() {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return headers;
  }
}
