import { TokenRefreshInterceptor } from './../../token-refresh-interceptor';
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpErrorResponse } from "@angular/common/http";
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import {
  NbAuthJWTToken, NbAuthModule, NbPasswordAuthStrategy, NbPasswordAuthStrategyOptions, NbTokenLocalStorage,
  getDeepFromObject
} from "@nebular/auth";
import { NbRoleProvider, NbSecurityModule } from "@nebular/security";
import { environment } from "../../../environments/environment";
import { BearerInterceptor } from "../../bearer-interceptor";
import { accessControl } from '../../config/role-config';
import { AuthGuard } from "../../providers/auth.guard";
import { RoleProvider } from '../../providers/role.provider';
import { LoginService } from '../../service/login.service';
import { UserService } from '../../service/user.service';
import { RedirectGuard } from './../../providers/redirect.guard';
import { UserData } from "./data/users";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { AuthGuardService } from './services/auth-guard.service';
import {
  LayoutService,
  StateService
} from "./utils";



const baseUrl = environment.backend.baseURL;

let loginRedirectUrl = "/pages/dashboard";

const socialLinks = [
  {
    url: "https://github.com/akveo/nebular",
    target: "_blank",
    icon: "github",
  },
  {
    url: "https://www.facebook.com/akveo/",
    target: "_blank",
    icon: "facebook",
  },
  {
    url: "https://twitter.com/akveo_inc",
    target: "_blank",
    icon: "twitter",
  },
];

const DATA_SERVICES = [
  AuthGuardService,
  LayoutService,
  StateService,
  LoginService,
  NbTokenLocalStorage,
  { provide: NbRoleProvider, useClass: RoleProvider },
  { provide: UserData, useClass: UserService },
];

const INTERCEPTORS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: BearerInterceptor,
    multi: true,
    deps: [LoginService]
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenRefreshInterceptor,
    multi: true,
    deps: [LoginService]
  },
];

export const NB_CORE_PROVIDERS = [
  // ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...INTERCEPTORS,
  ...NbAuthModule.forRoot({
    strategies: [
      NbPasswordAuthStrategy.setup({
        name: "username",
        baseEndpoint: baseUrl,
        login: {
          endpoint: "/auth/v2/signin",
          method: "POST",
          redirect: {
            success: loginRedirectUrl,
          },
          defaultMessages: [null],
          defaultErrors: ['Falha ao fazer Login.'],
        },
        logout: {
          redirect: {
            success: '/auth/login',
            failure: null, // stay on the same page
          },
        },
        token: {
          class: NbAuthJWTToken,
          key: "access_token",
        },
        refreshToken: {
          endpoint: '/auth/v2/refresh-token',
          method: 'POST',
          requireValidToken: true,
          redirect: {
            success: null,
            failure: null,
          },
          defaultErrors: ['Something went wrong, please try again.'],
          defaultMessages: ['Your token has been successfully refreshed.'],
        },
        errors: {
          key: 'message',
          getter: (module: string, res: HttpErrorResponse, options: NbPasswordAuthStrategyOptions) => getDeepFromObject(
            res.error,
            options.errors.key,
            options[module].defaultErrors,
          ),
        }
      }),
    ],
    forms: {
      login: {
        strategy: "username",
        socialLinks: socialLinks,
      },
      register: {
        socialLinks: socialLinks,
      },
    },
  }).providers,
  NbSecurityModule.forRoot(accessControl).providers,
  AuthGuard,
  RedirectGuard
];

@NgModule({
  imports: [CommonModule],
  exports: [NbAuthModule],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, private route: ActivatedRoute) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
    this.route.queryParams
      .subscribe(params => {
        loginRedirectUrl = decodeURIComponent(params.url);
      }
      );
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
        // { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [LoginService, NbTokenLocalStorage, Router] },
      ],
    };
  }
}
