import { environment } from '../../../../../environments/environment';
import { formatDate } from "@angular/common";
import { Component } from "@angular/core";

@Component({
  selector: "ngx-footer",
  styleUrls: ["./footer.component.scss"],
  template: `
    <span class="created-by pull-right m-3">
      <b>Joias Encante<a href="#" target="_blank"></a></b> - {{ date }} - Versão {{this.environment.version}}
    </span>
    <div class="socials">
      <!-- <a href="#" target="_blank" class="ion ion-social-github"></a> -->
      <a href="#" target="_blank" class="ion ion-social-facebook"></a>
      <!-- <a href="#" target="_blank" class="ion ion-social-twitter"></a> -->
      <!-- <a href="#" target="_blank" class="ion ion-social-linkedin"></a> -->
      <a
        href="https://www.instagram.com/joiasencanteacess/"
        target="_blank"
        class="ion ion-social-instagram"
      ></a>
    </div>
  `,
})
export class FooterComponent {
  environment = environment
  date = formatDate(new Date(), "yyyy", "en");
}
