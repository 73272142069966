import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NbAuthJWTToken, NbAuthService, NbAuthToken, NbTokenLocalStorage, NbTokenService } from "@nebular/auth";
import { User } from './../model/user';

@Injectable({
  providedIn: "root",
})
export class LoginService {

  user: User;

  token: NbAuthToken;

  constructor(
    private service: NbTokenService,
    private router: Router,
    private http: HttpClient,
    private tokenStorage: NbTokenLocalStorage,
    private authService: NbAuthService
  ) {

    this.service = service;

    // Instancia e observa alterações do token
    if (service != undefined) {
      this.service.tokenChange().subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.token = token;
          this.user = token.getPayload().user;
        } else {
          this.token = null;
        }
      });
    }
  }

  clearToken(): void {
    this.service.clear();
  }

  public isAuthenticated(): boolean {
    return this.token.isValid();
  }

  public logout() {
    console.log('logout');
    this.clearToken();
    sessionStorage.clear();
    this.router.navigate(["/auth/login"]);
  }

  public isAdmin() {
    return this.user.userRole.forEach((val) => {
      if (val.role === 'ADMIN') {
        return true;
      }
    });
  }

  public getUser() {
    return this.user;
  }

  public getToken(): NbAuthToken {
    return this.token;
  }

  public setToken(token: NbAuthJWTToken) {
    this.service.set(token).subscribe(() => {
      return true;
    }, (error) => {
      console.error(error);
      return false;
    });
  }

  public refreshToken() {
    this.token = new NbAuthJWTToken(this.tokenStorage.get().getValue(), 'username');
    return this.authService.refreshToken('username', {'token' : this.token});

    // let headers = new HttpHeaders();
    // headers = headers.set('content-type', 'application/json');
    // headers = headers.set('Access-Control-Allow-Origin', '*');

    // headers = headers.set('isRefreshToken', 'true');

    // return this.http.post<any>(
    //   environment.backend.baseURL + `/auth/v2/refresh-token`,
    //   {
    //   },
    //   {
    //     headers: headers, params: {}
    //   });
  }

  // helper methods

  private refreshTokenTimeout;

  public startRefreshTokenTimer() {
    const token: NbAuthToken = this.getToken();
    if (token) {
      // parse json object from base64 encoded jwt token
      const jwtToken = JSON.parse(atob(token.getValue().split('.')[1]));
      // set a timeout to refresh the token a minute before it expires
      const expires = new Date(jwtToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - (60 * 1000);
      this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe((data => {
        this.setToken(new NbAuthJWTToken(data?.getToken, 'username'));
      }
      )), timeout);
    }
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  public redirect() {
    this.router.navigate(['/pages/product']);
  }
}
