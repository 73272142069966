
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { NbRoleProvider } from '@nebular/security';


@Injectable()
export class RoleProvider implements NbRoleProvider {

    constructor(private authService: NbAuthService) { }

    /**
     *Obtem roles do payload
     *
     * @return {*}  {Observable<string>}
     * @memberof RoleProvider
     */
    getRole(): Observable<string> {
        return this.authService.onTokenChange()
            .pipe(
                map((token: NbAuthJWTToken) => {
                    if (token) {
                        const roles = token?.getPayload()['roles'];
                        return token.isValid() ? roles : 'guest';
                    }
                }),
            );
    }
}