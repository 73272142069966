import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbAuthJWTToken, NbAuthResult, NbAuthToken } from '@nebular/auth';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { LoginService } from './service/login.service';

/**
 * Intercept every request and add a token to header.
 *
 * @export
 * @class BearerInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class TokenRefreshInterceptor implements HttpInterceptor {

  private token: NbAuthToken;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);


  constructor(private loginService: LoginService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    req = req.clone({
      withCredentials: true
    });

    return next.handle(req).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse
        && !req.url.includes('auth/v2/signin')
        && (error.status === 401 || error.status === 403)
      ) {
        return this.handle401Error(req, next);
      }
      return next.handle(req);
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.loginService.refreshToken().pipe(
        switchMap((resultToken: NbAuthResult) => {
          if (resultToken && resultToken.getToken()) {
            this.loginService.setToken(new NbAuthJWTToken(resultToken.getToken().getValue(), 'username'));
            this.refreshTokenSubject.next(resultToken.getToken().getValue());
            this.isRefreshing = false;
            return next.handle(this.addToken(request, resultToken.getToken().getValue()));
          }
          this.isRefreshing = false;
          return throwError(new Error('Token refresh failed'));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          if (err.status === 403) {
            this.loginService.logout();
          }
          return throwError(err);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => next.handle(this.addToken(request, token as string)))
      );
    }
  }
}
