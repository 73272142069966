import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component } from "@angular/core";

@Component({
  selector: "ngx-recruitment-layout",
  styleUrls: ["./recruitment.layout.scss"],
  template: `
    <nb-layout id="recruitment-layout" windowMode>
      <nb-layout-header fixed>
        <ngx-recruitment-header class="w-100"></ngx-recruitment-header>
      </nb-layout-header>

      <nb-sidebar *ngIf="mobile" class="menu-sidebar" style="background-color: black;" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column style="padding: 0!important;">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer>
        <ngx-public-footer></ngx-public-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class RecruitmentLayoutComponent {

  mobile: boolean;

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {
    // detect screen size changes
    this.breakpointObserver.observe([
      "(max-width: 768px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  }
}
